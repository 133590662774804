import './Info.css';
import { useRef, useState } from 'react';
import useClickOutside from './useClickOutside';

function Info() {
  const [displayInfo, setDisplayInfo] = useState(false);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setDisplayInfo(false);
  });

  return (
    <div className="Info" ref={wrapperRef}>
      <button
        className="Info__button"
        onClick={() => {
          setDisplayInfo(!displayInfo);
        }}
      >
        <img src="info-mini.svg" alt="Information" />
      </button>
      {displayInfo && (
        <ul className="Info__items">
          <li>
            <a
              href="https://tiliaverse.com/a/faq"
              rel="noreferrer"
              target="_blank"
            >
              FAQ
            </a>
          </li>
          <li className="Info__item Info__item--divider"></li>
          <li>
            <a
              href="https://tiliaverse.com/policies/terms-of-service"
              rel="noreferrer"
              target="_blank"
            >
              Terms and conditions
            </a>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Info;
