import axios from "axios";
import { useState } from "react";
import "./Reply.css";
import TextArea from "./TextArea";

const Reply = ({
  replyId,
  account,
  recipient,
  conversationId,
  signed,
  onMessageSent,
}) => {
  const [reply, setReply] = useState("");

  const handleReply = (conversationId) => {
    axios
      .post("crypto_auth/send_user_message.php", {
        recipient,
        body: reply,
        address: account,
        signed,
        conversation_id: conversationId,
      })
      .then(() => {
        setReply("");
        onMessageSent();
      });
  };
  return (
    <div key={replyId} className="Reply">
      <TextArea
        label="Reply"
        name="reply"
        rows="2"
        onChange={(e) => setReply(e.target.value)}
      />
      <div className="Reply__actions">
        <button
          onClick={(event) => {
            event.stopPropagation();
            handleReply(conversationId);
          }}
          disabled={!reply || !reply.trim()}
        >
          <ion-icon name="send" size="small"></ion-icon>
        </button>
      </div>
    </div>
  );
};

export default Reply;
