import { Fill, Icon, Stroke, Circle as CircleStyle } from 'ol/style';
import { Filter } from './Filter';

export const MapMarkerStyle = {
  SINGLE_SOLD: new Icon({
    imgSize: [32, 64],
    offset: [0, 0],
    src: 'location.svg',
    color: '#be9e9d',
  }),
  SINGLE_NOT_SOLD: new Icon({
    imgSize: [32, 64],
    offset: [0, 0],
    src: 'location.svg',
    color: '#7c5050',
  }),
  SINGLE_FOUND: new Icon({
    imgSize: [32, 64],
    offset: [0, 0],
    src: 'location.svg',
    color: '#f46c62',
  }),
  SINGLE_OWNED: new Icon({
    imgSize: [32, 64],
    offset: [0, 0],
    src: 'location.svg',
    color: '#012f04',
  }),
  CLUSTERED: (filter) =>
    new CircleStyle({
      radius: 13,
      fill: new Fill({
        color:
          filter === Filter.PRIMARY
            ? '#7c5050'
            : filter === Filter.SECONDARY
            ? '#be9e9d'
            : '#728378',
      }),
      stroke: new Stroke({
        color: '#FFF',
      }),
    }),
  CLUSTERED_FOUND: new CircleStyle({
    radius: 13,
    fill: new Fill({
      color: '#728378',
    }),
  }),
  SEARCH_RESULT: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: '#f46c62',
    }),
  }),
  CLUSTERED_OWNED: (filter) =>
    new CircleStyle({
      radius: 13,
      fill: new Fill({
        color:
          filter === Filter.PRIMARY
            ? '#7c5050'
            : filter === Filter.SECONDARY
            ? '#be9e9d'
            : '#728378',
      }),
      stroke: new Stroke({
        color: '#012f04',
        width: 4,
      }),
    }),
};
