import './UpdateLocationDetails.css';
import Input from './Input';
import Button from './Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { validateUrl } from './validationUtils';

function UpdateLocationDetails({
  details,
  account,
  contract,
  nftId,
  onClose,
  onSnackbar,
  onGetSignedNonce,
  onFeatureUpdated,
}) {
  const [data, setData] = useState({
    owner: details.custom_name,
    websiteUrl: details.custom_link,
  });
  const [changed, setChanged] = useState(false);
  const [signing, setSigning] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const isUrlValid = !data.websiteUrl || !!validateUrl(data.websiteUrl);
    const errors = [];
    if (!isUrlValid) errors.push('websiteUrl');
    if (!data.owner) errors.push('owner');
    setErrors(errors);

    setChanged(
      data.owner !== details.custom_name ||
        details.custom_link !== data.websiteUrl
    );
  }, [data, details.custom_link, details.custom_name]);

  return (
    <div className="UpdateLocationDetails">
      <section className="UpdateLocationDetails__section">
        <button
          className="UpdateLocationDetails__section-close"
          onClick={onClose}
        >
          <img src="close-large.svg" alt="Close" />
        </button>
        <header className="UpdateLocationDetails__header">
          <h1 className="UpdateLocationDetails__header-title">
            Update property details
          </h1>
        </header>
        <form
          className="UpdateLocationDetails__section-form"
          onSubmit={(e) => e.preventDefault()}
        >
          <Input
            label="Property name *"
            invalid={errors.includes('owner')}
            placeholder=""
            name="owner"
            onChange={(e) => setData({ ...data, owner: e.target.value })}
            defaultValue={data.owner}
          />
          <Input
            label="Website Url"
            invalid={errors.includes('websiteUrl')}
            placeholder=""
            name="websiteUrl"
            onChange={(e) => setData({ ...data, websiteUrl: e.target.value })}
            defaultValue={data.websiteUrl}
          />

          <Button
            className="UpdateLocationDetails__update"
            label={
              signing
                ? 'Please check your wallet...'
                : updating
                ? 'Updating...'
                : 'Update'
            }
            primary
            disabled={
              !changed || signing || updating || (errors && errors.length > 0)
            }
            onClick={async () => {
              setSigning(true);
              const signedNonce = await onGetSignedNonce(account);
              setSigning(false);
              setUpdating(true);
              axios
                .post('crypto_auth/update_properties_details.php', {
                  signed: signedNonce,
                  address: account,
                  owner: data.owner,
                  ownerWebsite: data.websiteUrl,
                  contract: contract,
                  id: nftId,
                })
                .then(() => {
                  setUpdating(false);
                  onFeatureUpdated();
                  onClose();
                })
                .catch((e) => {
                  setUpdating(false);
                  onSnackbar({
                    type: 'error',
                    text: 'An error occured when updating the data. Please retry.',
                  });
                });
            }}
          ></Button>
        </form>
      </section>
    </div>
  );
}

export default UpdateLocationDetails;
