import { useRef } from 'react';
import './Signing.css';
import useClickOutside from './useClickOutside';

function Signing({ onClose }) {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => onClose && onClose());

  return (
    <div className="Signing">
      <div ref={wrapperRef} className="Signing__signing">
        Please check your wallet...
      </div>
    </div>
  );
}

export default Signing;
