import "./App.css";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Fill, Style, Text } from "ol/style";
import { Cluster, OSM, Vector, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { boundingExtent } from "ol/extent";
import { GeoJSON } from "ol/format";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { fromLonLat, transform } from "ol/proj";
import Menu from "./Menu";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import FilterMenu from "./FilterMenu";
import Snackbar from "./Snackbar";
import { v4 as uuidv4 } from "uuid";
import { getSignedNonce } from "./accountUtils";
import { MapMarkerStyle } from "./mapUtils";
import Search from "./Search";
import Profile from "./Profile";
import Web3 from "web3";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Signing from "./Signing";
import Location from "./Location";
import { getOwnedNFTs } from "./nftUtils";
import ThreeDMap from "./ThreeDMap";
import { getCurrentPoint, isPointDefined } from "./browserUtils";
import { Filter } from "./Filter";
import { LOCAL_STORAGE, updateUserInfo } from "./storageUtils";
import Owner from "./Owner";
import Info from "./Info";
import Mailbox from "./Mailbox";
import Buy from "./Buy";

function App() {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [nbDisplayedLocations, setNbDisplayedLocations] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [tooltip, setTooltip] = useState(null);
  const [userNFTs, setUserNFTs] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [userAccount, setUserAccount] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [openOwner, setOpenOwner] = useState(false);
  const [signing, setSigning] = useState(false);
  const [filter, setFilter] = useState(Filter.ALL);
  const [refreshMap, setRefreshMap] = useState(false);
  const [config, setConfig] = useState({});
  const [display3DMap, setDisplay3DMap] = useState(false);
  const [properties, setProperties] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [openMailbox, setOpenMailbox] = useState(false);
  const [openBuy, setOpenBuy] = useState(false);

  let refSelectedProperty = useRef(null);
  let map = useRef(null);
  let mainLayer = useRef(null);
  let searchLayer = useRef(null);
  let refUserNFTs = useRef([]);
  let web3Provider = useRef(null);
  let refFilter = useRef(null);

  // load user info
  useEffect(() => {
    const userInfo = localStorage.getItem(LOCAL_STORAGE.PROFILE)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.PROFILE))
      : null;
    if (userInfo) {
      setUserInfo(userInfo);
    }
  }, []);

  useEffect(() => {
    const origOpen = XMLHttpRequest.prototype.open;
    const onLoad = function () {
      if (
        Object.values(Filter)
          .map((f) => f.source)
          .some((u) => this.responseURL.includes(u))
      ) {
        const data = JSON.parse(this.responseText);
        setProperties(data.features);

        const center = getCurrentPoint();
        if (isPointDefined(center)) {
          const feature = data.features.find((f) => {
            const coordinates = fromLonLat(f.geometry.coordinates);
            return (
              coordinates[0].toString() === center[0] &&
              coordinates[1].toString() === center[1]
            );
          });
          if (feature) {
            const pixel = map.current.getPixelFromCoordinate(center);
            const {
              name,
              contract,
              image_url,
              sold,
              nft_id,
              shopify_handle,
              custome_name,
              custom_link,
            } = feature.properties;
            setSelectedProperty({
              name,
              contract,
              image_url,
              sold,
              nft_id,
              shopify_handle,
              custom_name: custome_name,
              custom_link,
              coordinates: center,
              originalCoordinates: transform(
                feature.geometry.coordinatesates,
                "EPSG:3857",
                "EPSG:4326",
              ),
              position: { x: pixel[0] + 32, y: pixel[1] - 16 },
            });
          }
        }

        if (mainLayer.current) mainLayer.current.getSource().changed();
      }
    };

    XMLHttpRequest.prototype.open = function () {
      this.addEventListener("load", onLoad);
      origOpen.apply(this, arguments);
    };

    return () => {
      this.removeEventListener("load", onLoad);
    };
  }, []);

  useEffect(() => {
    if (config.zoom && config.zoom >= 16) {
      setDisplay3DMap(true);
    }
    if (config.zoom && config.zoom < 16) {
      setDisplay3DMap(false);
    }
  }, [config]);

  useEffect(() => {
    refFilter.current = filter;
    const handleSourceLoaded = (event) => {
      const source = event.target;
      if (source.getState() === "ready") {
        const features = source.getFeatures();

        if (filter === Filter.YOURS) {
          // if YOURS is selected
          for (let i = 0; i < features.length; i++) {
            const element = features[i];
            if (!refUserNFTs.current.includes(element.get("nft_id"))) {
              source.removeFeature(element);
            }
          }
        }

        let nbFeatures = 0;
        const extent = map.current
          .getView()
          .calculateExtent(map.current.getSize());
        source.forEachFeatureInExtent(extent, function (feature) {
          nbFeatures++;
        });
        setNbDisplayedLocations(nbFeatures);
      }
    };

    if (mainLayer.current) {
      mainLayer.current.getSource().clear();
      const source = new VectorSource({
        url: filter.source,
        format: new GeoJSON({ featureProjection: "EPSG:4326" }),
        properties: { propertiesLayer: true },
      });

      source.once("change", handleSourceLoaded);

      const clusterSource = new Cluster({
        distance: 40,
        minDistance: 40,
        source: source,
        style: new Style({
          pointer: "cursor",
        }),
      });

      mainLayer.current.setSource(clusterSource);
    }
  }, [filter, refreshMap]);

  // initialize web3 provider
  async function onInitializeProviderClient() {
    web3Provider.current = await EthereumProvider.init({
      projectId: "cbb2117d8917c1718fa031259921a22d",
      showQrModal: true,
      qrModalOptions: { themeMode: "dark" },
      chains: [137],
      optionalChains: [5, 56, 137, 10, 100],
      methods: ["eth_sendTransaction", "personal_sign"],
      events: ["connect", "chainChanged", "accountsChanged"],
      metadata: {
        name: "Tilia Earth",
        description: "Tilia Earth",
        url: "https://tilia.earth/",
        icons: ["https://my-dapp.com/logo.png"],
      },
      rpcMap: {
        137: "https://polygon-mainnet.infura.io/v3/49b2dde3ad6f48a88f42fb7841c35fbe",
      },
    });

    if (web3Provider.current && web3Provider.current.connected) {
      const account = web3Provider.current.accounts[0];
      const nfts = await getOwnedNFTs(account);
      setUserNFTs(nfts);
    }

    web3Provider.current.on("accountsChanged", async (accounts) => {
      // get account
      const account = accounts[0];
      setUserAccount(account);

      // get user nfts
      const nfts = await getOwnedNFTs(account);
      setUserNFTs(nfts);

      setTimeout(async () => {
        // get nonce and ask signature
        setSigning(true);
        const web3 = new Web3(web3Provider.current);
        const signedNonce = await getSignedNonce(web3, account);
        setSigning(false);

        // login
        await axios.post("crypto_auth/login.php", {
          signed: signedNonce,
          address: account,
        });

        axios
          .get(
            `crypto_auth/get_profile.php?address=${account}&signed=${signedNonce}`,
          )
          .then(({ data }) => {
            setUserInfo(data);
          });
      }, 800);
    });
  }

  useEffect(() => {
    onInitializeProviderClient();
  }, []);

  // store user info
  useEffect(() => {
    updateUserInfo(userInfo);
  }, [userInfo]);

  const handleConnect = async () => {
    if (web3Provider.current) {
      const res = await web3Provider.current.connect();

      web3Provider.current.pollingInterval = 20000;
    } else {
      throw new Error("providerClient is not initialized");
    }
  };

  const handleDisconnect = async () => {
    if (web3Provider.current) {
      await web3Provider.current.disconnect();
    }

    setUserAccount(null);
    setUserNFTs([]);
    setUserInfo(null);
  };

  // build map
  useEffect(() => {
    // define source from endpoint
    const source = new VectorSource({
      url: "data.json",
      format: new GeoJSON({ featureProjection: "EPSG:4326" }),
      properties: { propertiesLayer: true },
    });

    const clusterSource = new Cluster({
      distance: 40,
      minDistance: 40,
      source: source,
      style: new Style({
        pointer: "cursor",
      }),
    });

    // define layer with the properties
    mainLayer.current = new VectorLayer({
      source: clusterSource,
      style: function (feature) {
        // selected property nftId
        const nftId = refSelectedProperty.current
          ? refSelectedProperty.current.nft_id
          : "-1";
        // selected features + nftIds
        const features = feature.get("features");
        const nftIds = features.reduce(
          (acc, f) => [...acc, f.get("nft_id")],
          [],
        );
        // any owned nfts
        const owned = !!features
          .reduce((acc, f) => [...acc, f.get("nft_id")], [])
          .find((n) => refUserNFTs.current.includes(n));
        const nbFeatures = features.length;
        // single property, not cluster
        const isSingle = nbFeatures === 1;
        const sold = isSingle ? features[0].get("sold") : false;
        const found = nftIds.includes(nftId);
        return new Style({
          image: isSingle
            ? owned
              ? MapMarkerStyle.SINGLE_OWNED
              : found
              ? MapMarkerStyle.SINGLE_FOUND
              : sold
              ? MapMarkerStyle.SINGLE_SOLD
              : MapMarkerStyle.SINGLE_NOT_SOLD
            : owned
            ? MapMarkerStyle.CLUSTERED_OWNED(refFilter.current)
            : found
            ? MapMarkerStyle.CLUSTERED_FOUND
            : MapMarkerStyle.CLUSTERED(refFilter.current),
          text: isSingle
            ? null
            : new Text({
                text: nbFeatures.toString(),
                fill: new Fill({
                  color: "#fff",
                }),
                font: "bold 11px sans-serif",
              }),
        });
      },
    });

    // search layer
    searchLayer.current = new VectorLayer({
      source: new Vector(),
      style: function (feature) {
        return new Style({
          image: MapMarkerStyle.SEARCH_RESULT,
        });
      },
    });

    // build tiles
    const tileLayer = new TileLayer({
      className: "TileLayer",
      source: new OSM(),
    });

    const center = getCurrentPoint();
    const centerFromUrl = isPointDefined(center);
    map.current = new Map({
      layers: [tileLayer, mainLayer.current, searchLayer.current],
      target: "map",
      view: new View(
        !centerFromUrl
          ? {
              center: [0, 0],
              zoom: 2,
              padding: [20, 20, 20, 20],
              // world extent
              extent: [
                -20037508.3427892, -20037508.3427892, 20037508.3427892,
                20037508.3427892,
              ],
            }
          : {
              center: [center[0], center[1]],
              zoom: center[2] && center[2] > 15 ? center[2] : 16,
              padding: [100, 100, 100, 100],
            },
      ),
    });

    map.current.on("movestart", function (e) {
      setSelectedProperty(null);
    });

    // get the properties currently displayed on the map
    map.current.on("moveend", function (e) {
      let nbFeatures = 0;
      const extent = e.map.getView().calculateExtent(e.map.getSize());
      mainLayer.current
        .getSource()
        .forEachFeatureInExtent(extent, function (feature) {
          const fs = feature.get("features");
          nbFeatures += fs.length;
        });
      setNbDisplayedLocations(nbFeatures);

      const zoom = e.map.getView().getZoom();
      const transformedCenter = transform(
        e.map.getView().getCenter(),
        "EPSG:3857",
        "EPSG:4326",
      );
      const transformedExtentNE = transform(
        [extent[0], extent[1]],
        "EPSG:3857",
        "EPSG:4326",
      );
      const transformedExtentSW = transform(
        [extent[2], extent[3]],
        "EPSG:3857",
        "EPSG:4326",
      );
      setConfig({
        zoom,
        center: transformedCenter,
      });

      const payload = {
        wallet_address: userAccount,
        zoomLevel: zoom,
        center: { lon: transformedCenter[0], lat: transformedCenter[1] },
        bounds: {
          ne: { lon: transformedExtentNE[0], lat: transformedExtentNE[1] },
          sw: { lon: transformedExtentSW[0], lat: transformedExtentSW[1] },
        },
      };
      axios
        .post("crypto_auth/get_gift.php", payload)
        .then(({ data }) => {})
        .catch((err) => {
          console.error(err);
        });
    });

    // set marker cursor to pointer
    // set tooltip for search results properties
    map.current.on("pointermove", function (e) {
      const pixel = e.map.getEventPixel(e.originalEvent);
      const hit = e.map.hasFeatureAtPixel(pixel);
      e.map.getViewport().style.cursor = hit ? "pointer" : "";

      const feature = map.current.forEachFeatureAtPixel(
        pixel,
        function (feature) {
          return feature;
        },
      );
      if (feature && feature.get("search")) {
        setTooltip({
          name: feature.get("name"),
          position: [pixel[0] - 80, pixel[1] + 10],
        });
      } else {
        setTooltip(null);
      }
    });

    // add click evetn to cluster and properties
    map.current.on("click", (e) => {
      mainLayer.current.getFeatures(e.pixel).then((clickedFeatures) => {
        if (clickedFeatures.length > 0) {
          // Get clustered Coordinates
          const features = clickedFeatures[0].get("features");
          // only one location selected
          if (clickedFeatures.length === 1 && features.length === 1) {
            const feature = features[0];
            const coordinates = feature.getGeometry().getCoordinates();
            const pixel = e.map.getPixelFromCoordinate(coordinates);
            setSelectedProperty({
              name: feature.get("name"),
              contract: feature.get("contract"),
              image_url: feature.get("image_url"),
              sold: feature.get("sold"),
              nft_id: feature.get("nft_id"),
              shopify_handle: feature.get("shopify_handle"),
              custom_name: feature.get("custom_name"),
              custom_link: feature.get("custom_link"),
              coordinates: coordinates,
              originalCoordinates: transform(
                coordinates,
                "EPSG:3857",
                "EPSG:4326",
              ),
              position: { x: pixel[0] + 32, y: pixel[1] - 16 },
            });
          } else {
            setSelectedProperties(features.map((f) => f.get("nft_id")));
            setSelectedProperty(null);
          }
        } else {
          setSelectedProperty(null);
        }
      });
    });
  }, []);

  // when the user search find results
  const handleSearchResultsChange = (results) => {
    if (results.length > 0) {
      const extent = boundingExtent(
        results.map((r) => fromLonLat([r.lon, r.lat])),
      );
      map.current.getView().fit(extent, {
        duration: 500,
        padding: [100, 100, 100, 100],
        maxZoom: 16,
      });

      const features = results.map(
        (element) =>
          new Feature({
            name: element.display_name,
            search: true,
            geometry: new Point(
              transform([element.lon, element.lat], "EPSG:4326", "EPSG:3857"),
            ),
          }),
      );

      setProperties([
        ...properties.filter((f) => !f.properties.search),
        ...results.map((r) => ({
          geometry: { coordinates: [r.lon, r.lat] },
          properties: { name: r.display_name, search: true },
        })),
      ]);

      searchLayer.current.getSource().clear();

      features.forEach((f) => {
        searchLayer.current.getSource().addFeature(f);
      });
    } else {
      searchLayer.current.getSource().clear();
      handleExpandMap();
    }
  };

  // when the user selet some locations, the map is zoomed to those
  useEffect(() => {
    const results =
      selectedProperties.length === 0
        ? properties
        : properties.filter((p) =>
            selectedProperties.includes(p.properties.nft_id),
          );

    if (map.current && results.length > 0) {
      const extent = boundingExtent(
        results.map((r) => fromLonLat(r.geometry.coordinates)),
      );
      map.current.getView().fit(extent, {
        duration: 0,
        padding: [100, 100, 100, 100],
        maxZoom: 16,
      });
    }
  }, [selectedProperties]);

  // when one property is selected
  useEffect(() => {
    refSelectedProperty.current = selectedProperty;
    if (map.current) {
      // get vectorLayer and refresh
      map.current.getLayers().item(1).getSource().changed();
    }
  }, [selectedProperty]);

  // when user's nfts change, refresh the map
  useEffect(() => {
    refUserNFTs.current = userNFTs;
    if (mainLayer.current) mainLayer.current.getSource().changed();
  }, [userNFTs]);

  const handleExpandMap = () =>
    setSelectedProperties(properties.map((p) => p.properties.nft_id));

  const handleOpenOwner = (owner) => {
    setOpenOwner(owner);
  };

  const handleResolveNFTs = (nfts) =>
    properties.filter((p) => nfts.includes(p.properties.nft_id));

  const handleOpenBuy = (nftId) => setOpenBuy(nftId);

  const handleGetSignedNonce = async (account) => {
    const web3 = new Web3(web3Provider.current);
    setSigning(true);
    const signature = await getSignedNonce(web3, account);
    setSigning(false);
    return signature;
  };

  return (
    <div className="App">
      {openProfile && (
        <Profile
          userInfo={userInfo}
          account={userAccount}
          web3={new Web3(web3Provider.current)}
          onClose={() => setOpenProfile(!openProfile)}
          onProfileUpdated={(profile) => {
            setUserInfo(profile);
            setSnackbar({
              type: "success",
              text: "Your profile is successfully updated.",
            });
            setTimeout(() => {
              setSnackbar(null);
            }, 3000);
          }}
        />
      )}

      <Search
        nbDisplayedLocations={nbDisplayedLocations}
        onSearchResultsChange={handleSearchResultsChange}
        onOpenOwner={handleOpenOwner}
      />

      <Menu
        connected={
          web3Provider.current && web3Provider.current.accounts.length > 0
            ? web3Provider.current.accounts[0]
            : null
        }
        userInfo={userInfo}
        onConnectWallet={handleConnect}
        onLogout={handleDisconnect}
        onContactSent={() => {
          setSnackbar({
            type: "success",
            text: "Your message has been successfully sent.",
          });
          setTimeout(() => {
            setSnackbar(null);
          }, 4000);
        }}
        onOpenProfile={() => {
          setSelectedProperty(null);
          setOpenProfile(true);
        }}
        onOpenOwner={handleOpenOwner}
        onOpenMailbox={() => setOpenMailbox(true)}
      />

      <div id="map" className="App__map map"></div>

      {selectedProperty && (
        <Location
          account={userAccount}
          location={selectedProperty}
          ownedNFTs={userNFTs}
          properties={properties}
          onClose={() => setSelectedProperty(null)}
          onZoom={(nft) => {
            setSelectedProperties([nft]);
          }}
          onGetSignedNonce={handleGetSignedNonce}
          onFeatureUpdated={() => {
            setSelectedProperty(null);
            setSnackbar({
              type: "success",
              text: "Data successfully updated.",
            });
            setTimeout(() => {
              setSnackbar(null);
            }, 4000);
            setRefreshMap(!refreshMap);
          }}
          onResolveNFTs={handleResolveNFTs}
          onOpenOwner={handleOpenOwner}
          onOpenBuy={handleOpenBuy}
        />
      )}

      <a className="App__logo" href="/">
        <img className="App__logo-image" src="logo.svg" alt="Tilia.Earth" />
      </a>

      <button
        className={`App__expand ${display3DMap ? "App__expand--3d" : ""}`}
        onClick={handleExpandMap}
      >
        <img src="expand.svg" alt="Zoom out" />
      </button>

      <FilterMenu
        filter={filter}
        onFiltered={(choice) => {
          let f = choice;
          if (filter === choice) {
            f = Filter.ALL;
          }
          setFilter(f);
        }}
      />

      <Info />

      {tooltip && (
        <p
          style={{
            top: `${tooltip.position[1]}px`,
            left: `${tooltip.position[0]}px`,
          }}
          className="App__tooltip"
        >
          {tooltip.name}
        </p>
      )}

      {snackbar && (
        <Snackbar type={snackbar.type} text={snackbar.text} id={uuidv4()} />
      )}

      {signing && <Signing onClose={() => setSigning(!signing)} />}

      {display3DMap && (
        <ThreeDMap
          lon={config.center[0]}
          lat={config.center[1]}
          zoom={config.zoom}
          properties={properties}
          userAccount={userAccount}
          userNFTs={userNFTs}
          onClose={({ zoom, center }) => {
            setDisplay3DMap(false);
            map.current
              .getView()
              .setCenter(fromLonLat([center.lng(), center.lat()]));
            map.current.getView().setZoom(zoom);
          }}
          onZoom={(nft) => {
            setSelectedProperties([nft]);
          }}
          onOpenOwner={handleOpenOwner}
          onOpenBuy={handleOpenBuy}
          onGetSignedNonce={handleGetSignedNonce}
        />
      )}

      {openOwner && (
        <Owner
          account={openOwner}
          onClose={() => setOpenOwner(null)}
          onZoom={(nftId) => {
            setSelectedProperties([nftId]);
          }}
          onResolveNFTs={handleResolveNFTs}
        />
      )}

      {openMailbox && (
        <Mailbox
          web3={new Web3(web3Provider.current)}
          account={userAccount}
          onClose={() => setOpenMailbox(false)}
          onGetSignedNonce={handleGetSignedNonce}
        />
      )}

      {openBuy && (
        <Buy
          nftId={openBuy}
          onClose={() => {
            setOpenBuy(null);
            setSelectedProperty(null);
          }}
        />
      )}
    </div>
  );
}

export default App;
