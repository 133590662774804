import './PageNotFound.css';

function PageNotFound() {
  return (
    <div className="PageNotFound__container">
      <section className="PageNotFound">
        <h1 className="PageNotFound__title">Page Not Found</h1>
        <p className="PageNotFound__description">
          The resource you are trying to reach does not exist.
          <br />
          Please check the browser URL or go <a href="/">home</a>.
        </p>
      </section>
    </div>
  );
}

export default PageNotFound;
