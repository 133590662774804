import axios from 'axios';
import { fromLonLat } from 'ol/proj';
import { useEffect, useRef, useState } from 'react';
import Location from './Location';
import './ThreeDMap.css';

function ThreeDMap({
  lon,
  lat,
  zoom,
  properties,
  userAccount,
  userNFTs,
  onClose,
  onZoom,
  onOpenOwner,
  onOpenBuy,
  onGetSignedNonce,
}) {
  const [location, setLocation] = useState(null);

  let map = useRef(null);

  const handleMapChanged = () => {
    if (map.current) {
      const zoom = map.current.getZoom();
      const center = map.current.getCenter();
      const bounds = map.current.getBounds();
      const payload = {
        wallet_address: userAccount,
        zoomLevel: zoom,
        center: { lon: center._lng, lat: center._lat },
        bounds: {
          ne: { lon: bounds.ne._lng, lat: bounds.ne._lat },
          sw: { lon: bounds.sw._lng, lat: bounds.sw._lat },
        },
      };
      axios
        .post('crypto_auth/get_gift.php', payload)
        .then(({ data }) => {})
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    const { LatLng, MapTypeId, Map, Marker, ControlPosition } = window.f4.map;

    if (!map.current) {
      const myLatlng = new LatLng(lat, lon);
      const mapOptions = {
        minZoom: 15,
        maxZoom: 19,
        zoom: zoom && zoom > 15 ? zoom : 16,
        center: myLatlng,
        mapTypeId: MapTypeId.FLAT_TERRAIN,
        mapTypeControlOptions: { position: ControlPosition.BOTTOM_RIGHT },
      };

      map.current = new Map('#3d-map', mapOptions);

      map.current.on('zoom_changed', function (e) {
        const zoom = map.current.getZoom();
        const center = map.current.getCenter();
        setLocation(null);
        if (zoom < 16) {
          onClose({ zoom, center });
        } else {
          handleMapChanged();
        }
      });

      map.current.on('dragstart', function (e) {
        setLocation(null);
      });

      map.current.on('dragend', function (e) {
        handleMapChanged();
      });

      map.current.on('click', function (e) {
        setLocation(null);
      });

      handleMapChanged();
    }

    map.current.setCenter(new LatLng(lat, lon));

    properties.forEach((feature) => {
      const marker = new Marker({
        position: {
          lat: feature.geometry.coordinates[1],
          lng: feature.geometry.coordinates[0],
        },
        map: map.current,
        clickable: true,
        icon: {
          url: feature.properties.search
            ? 'ellipse.svg'
            : feature.properties.sold
            ? userNFTs.includes(feature.properties.nft_id)
              ? 'location-black.svg'
              : 'location-red.svg'
            : 'location-brawn.svg',
        },
      });

      marker.on('click', (event) => {
        setLocation({
          name: feature.properties.name,
          contract: feature.properties.contract,
          image_url: feature.properties.image_url,
          sold: feature.properties.sold,
          nft_id: feature.properties.nft_id,
          shopify_handle: feature.properties.shopify_handle,
          custom_name: feature.properties.custome_name,
          custom_link: feature.properties.custom_link,
          coordinates: fromLonLat([event.latLng.lng(), event.latLng.lat()]),
          originalCoordinates: [event.latLng.lng(), event.latLng.lat()],
          position: { x: event.clientX + 32, y: event.clientY },
        });
      });
    });
  }, [lon, lat, properties, userNFTs, zoom, onClose]);

  return (
    <main className="ThreeDMap">
      <div id="3d-map" className="ThreeDMap__map"></div>

      {location && (
        <Location
          location={location}
          account={userAccount}
          ownedNFTs={userNFTs}
          properties={properties}
          onClose={() => setLocation(null)}
          onZoom={(nftId) => {
            setLocation(null);
            onZoom(nftId);
          }}
          onGetSignedNonce={onGetSignedNonce}
          onFeatureUpdated={() => {}}
          onResolveNFTs={(nfts) =>
            properties.filter((p) => nfts.includes(p.properties.nft_id))
          }
          onOpenOwner={onOpenOwner}
          onOpenBuy={onOpenBuy}
        />
      )}
    </main>
  );
}

export default ThreeDMap;
