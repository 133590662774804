import './About.css';

function About({ onClose }) {
  return (
    <div className="About">
      <section className="About__section">
        <button className="About__section-close" onClick={onClose}>
          <img src="close-large.svg" alt="Close" />
        </button>
        <header className="About__header">
          <h1 className="About__header-title">Welcome to Tilia.Earth!</h1>
          <p className="About__header-title-description">
            Your business map allowing you to buy and sell properties in a few
            clicks!
          </p>
        </header>

        <h2 className="About__subtitle">
          In a few words: easy to invest in NFTs
        </h2>

        <p>
          Tilia.Earth turns the real world into a virtual game where you can
          easily invest in NFTs as virtual property! Here, your assets in this
          virtual world have real value thanks to our digital economy built on
          the Polygon blockchain. By investing in the Tilia.Earth virtual world,
          you are taking part in a new virtual economy linked to the blockchain.
        </p>
        <p>
          This is the best time to invest in the metaverse, still a niche
          market. What if your investment of today turns out to be a real
          fortune tomorrow? We have big plans for our Tilia.Earth, and we want
          to make it become the global reference for all e-investors.
        </p>

        <h2 className="About__subtitle">What is Tilia.Earth?</h2>

        <p>
          Tilia.Earth is a blockchain-based virtual world for trading property
          designed for investors to explore cities, collect properties and meet
          other investors.{' '}
        </p>

        <h2 className="About__subtitle">Buying a property</h2>

        <p>
          Tilia.Earth and foremost a virtual world where you buy and sell
          property. Tilia.Earth investors explore cities to find rare and unique
          properties that can then be used to complete property collections or
          sold on the open market. Tilia.Earth gives you the ability to pull
          your NFT into your Wallet to give you the freedom to sell it elsewhere
          than in Tilia.Earth.
        </p>

        <p>
          Tilia.Earth also facilitates the acquisition of an NFT through payment
          by credit card, PayPal or other means.
        </p>

        <p>
          The vision of Tilia.Earth is the one of a digital Metaverse fully
          based on the real world. Eventually, Tilia.Earth plans to work with
          companies to offer e-shops, e-offices and more...
        </p>

        <h2 className="About__subtitle">
          A virtual world created for business
        </h2>

        <p>
          Tilia.Earth is both a virtual property exchange platform and a social
          experience. The highlight? We are creating a business community of
          people all around the globe.
        </p>
        <p>
          You also have the possibility to buy entire cities and countries, for
          that you can contact us for more information.
        </p>
        <p>
          Our growing community is the lifeblood of our ecosystem, you can be
          sure that we will expand our world's capabilities for investors to
          connect and make new connections.
        </p>

        <button className="About__section-master" onClick={onClose}>
          Become a Tilia.Earth Master!
        </button>
      </section>
    </div>
  );
}

export default About;
