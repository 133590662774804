/* eslint-disable no-undef */

import axios from 'axios';

// get nfts linked to account
export async function getOwnedNFTs(account) {
  if (!account) return [];

  const nbNftResponse = await axios.post('https://polygon-rpc.com', {
    jsonrpc: '2.0',
    id: 1,
    method: 'eth_call',
    params: [
      {
        from: '0x0000000000000000000000000000000000000000',
        to: '0xaA44745B7C72617e36C25c3ae0629cc284332118',
        data:
          '0x' +
          (BigInt('0x70a08231') * BigInt(16 ** 64) + BigInt(account)).toString(
            16
          ),
      },
      'latest',
    ],
  });

  const nbNFTs = nbNftResponse.data.result;
  const nftToQuery = parseInt(nbNFTs);
  const ownedNFTs = [];
  for (let i = 0; i < nftToQuery; i++) {
    const data_int =
      BigInt(
        0x2f745c5900000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
      ) +
      BigInt(account) * BigInt(16 ** 64) +
      BigInt(i);
    const nftIdResponse = await axios.post('https://polygon-rpc.com', {
      jsonrpc: '2.0',
      id: 1,
      method: 'eth_call',
      params: [
        {
          from: '0x0000000000000000000000000000000000000000',
          to: '0xaA44745B7C72617e36C25c3ae0629cc284332118',
          data: '0x' + data_int.toString(16),
        },
        'latest',
      ],
    });
    ownedNFTs.push(parseInt(nftIdResponse.data.result).toString());
  }
  // Now doing it with the old contract
  const oldNftOwnerResponse = await axios.get(
    'https://deep-index.moralis.io/api/v2/' +
      account +
      '/nft/0x2953399124f0cbb46d2cbacd8a89cf0599974963?chain=polygon&format=decimal',
    {
      headers: {
        'X-API-Key':
          '28jsb00WXnxwiLGsX3HoK6p4tTalxcvjJWf5oghQVlK3RHuj1UM1HXYUznTf4VPf',
      },
    }
  );

  oldNftOwnerResponse.data.result.forEach((nft) => {
    ownedNFTs.push(nft.token_id);
  });

  return ownedNFTs;
}

export async function getOwnerOfNFT(contract, nftId) {
  try {
    if (
      contract.toLowerCase() === '0xaa44745b7c72617e36c25c3ae0629cc284332118'
    ) {
      const dataInt =
        BigInt(
          0x6352211e0000000000000000000000000000000000000000000000000000000000000000
        ) + BigInt(nftId);
      const nftOwnerResponse = await axios.post('https://polygon-rpc.com', {
        jsonrpc: '2.0',
        id: 1,
        method: 'eth_call',
        params: [
          {
            from: '0x0000000000000000000000000000000000000000',
            to: '0xaA44745B7C72617e36C25c3ae0629cc284332118',
            data: '0x' + dataInt.toString(16),
          },
          'latest',
        ],
      });
      return {
        nftId,
        owner: '0x' + BigInt(nftOwnerResponse.data.result).toString(16),
      };
    } else if (
      contract.toLowerCase() === '0x2953399124f0cbb46d2cbacd8a89cf0599974963'
    ) {
      const oldNftOwnerResponse = await axios.get(
        'https://deep-index.moralis.io/api/v2/nft/0x2953399124f0cbb46d2cbacd8a89cf0599974963/' +
          nftId +
          '/owners?chain=polygon&format=decimal',
        {
          headers: {
            'X-API-Key':
              '28jsb00WXnxwiLGsX3HoK6p4tTalxcvjJWf5oghQVlK3RHuj1UM1HXYUznTf4VPf',
          },
        }
      );

      return {
        nftId,
        owner:
          '0x' +
          BigInt(oldNftOwnerResponse.data.result[0].owner_of).toString(16),
      };
    } else {
      return { nftId, owner: null };
    }
  } catch (e) {
    return { nftId, owner: null };
  }
}
