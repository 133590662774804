import './Button.css';

function Button({
  label,
  icon,
  onClick,
  flat,
  mini,
  primary,
  className,
  disabled,
}) {
  return (
    <button
      className={`Button ${flat ? 'Button--flat' : ''} ${
        mini ? 'Button--mini' : ''
      } ${primary ? 'Button--primary' : ''} ${className || ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <ion-icon class="Button__icon" name={icon}></ion-icon>}
      <span className="Button__label">{label}</span>
    </button>
  );
}

export default Button;
