import './Buy.css';
import Input from './Input';
import Button from './Button';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { validateEmail, validatePhone } from './validationUtils';

function Buy({ nftId, onClose }) {
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState([]);
  const [paymentId, setPaymentId] = useState(null);
  const refSimplexPaymentId = useRef(null);

  useEffect(() => {
    const errors = [];
    if (!data.email || !validateEmail(data.email)) errors.push('email');
    if (!data.phone || !validatePhone(data.phone)) errors.push('phone');
    setErrors(errors);
  }, [data]);

  const handleSimplexBuy = () => {
    axios
      .get(
        '/crypto_auth/get_simplex_payment.php?nftId=' +
          encodeURIComponent(nftId) +
          '&email=' +
          encodeURIComponent(data.email) +
          '&phone=' +
          encodeURIComponent(data.phone)
      )
      .then(({ data: { error, paymentId } }) => {
        console.error(error);
        if (error) {
          if (error.includes("Duplicate entry 'AWAITING_PAYMENT-")) {
            setError(
              'This property is already being purchased by someone else and has been reserved for 15 minutes. Please try again later.'
            );
          } else {
            setError(error);
          }
        } else {
          refSimplexPaymentId.current = paymentId;
          setPaymentId(paymentId);
          document.getElementById('buyOnSimplex').submit();

          refSimplexPaymentId.current = null;
          setPaymentId(null);
          onClose();
        }
      });
  };

  return (
    <div className="Buy">
      <section className="Buy__section">
        <button className="Buy__section-close" onClick={() => onClose(false)}>
          <img src="close-large.svg" alt="Close" />
        </button>
        <header className="Buy__header">
          <h2>Buy on Simplex</h2>
        </header>
        <hr />
        {!refSimplexPaymentId.current && (
          <form className="Buy__content" onSubmit={(e) => e.preventDefault()}>
            <Input
              label="Email"
              name="email"
              type="email"
              onChange={(e) => setData({ ...data, email: e.target.value })}
              invalid={errors.includes('email')}
            ></Input>
            <Input
              label="Phone (+41... / +1...)"
              name="phone"
              type="phone"
              onChange={(e) => setData({ ...data, phone: e.target.value })}
              invalid={errors.includes('phone')}
            ></Input>
            <Button
              primary
              label="Buy"
              className="Buy__simplex"
              onClick={handleSimplexBuy}
              disabled={
                !(
                  data.email &&
                  !!data.email.trim() &&
                  data.phone &&
                  !!data.phone.trim()
                )
              }
            />
          </form>
        )}
        {refSimplexPaymentId.current && (
          <form
            id="buyOnSimplex"
            className="Buy__buyingOnSimplex"
            action="https://checkout.simplexcc.com/payments/new"
            method="POST"
            target="_blank"
          >
            <input type="hidden" name="version" value="1"></input>
            <input type="hidden" name="partner" value="tilia"></input>
            <input
              type="hidden"
              name="payment_flow_type"
              value="wallet"
            ></input>
            <input
              type="hidden"
              name="return_url_success"
              value="https://tilia.earth/metamask_static/payment_success.html"
            ></input>
            <input
              type="hidden"
              name="return_url_fail"
              value="https://tilia.earth/metamask_static/payment_failed.html"
            ></input>
            <input
              type="hidden"
              name="payment_id"
              value={refSimplexPaymentId.current}
            ></input>
          </form>
        )}
        {error && <p className="Buy__error">{error}</p>}
      </section>
    </div>
  );
}

export default Buy;
