import './Contact.css';
import Input from './Input';
import TextArea from './TextArea';
import Button from './Button';
import { useEffect, useState } from 'react';
import axios from 'axios';

function Contact({ onClose, onContactSent }) {
  const [data, setData] = useState({
    name: '',
    phone: '',
    sender: '',
    message: '',
    subject: 'I am trying to contact you',
  });
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(null);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    const v =
      data.name.trim() &&
      data.phone.trim() &&
      validateEmail(data.sender.trim()) &&
      data.message.trim();
    setValid(v);
  }, [data]);

  return (
    <div className="Contact">
      <section className="Contact__section">
        <button className="Contact__section-close" onClick={onClose}>
          <img src="close-large.svg" alt="Close" />
        </button>
        <header className="Contact__header">
          <h1 className="Contact__header-title">Contact Us</h1>
          <p className="Contact__header-title-description">
            Any question you would like to ask?
          </p>
        </header>
        <form
          className="Contact__section-form"
          onSubmit={(e) => e.preventDefault()}
        >
          <Input
            label="Name"
            placeholder="Your name..."
            name="name"
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
          <Input
            label="Email"
            placeholder="Your email..."
            name="email"
            onChange={(e) => setData({ ...data, sender: e.target.value })}
          />
          <Input
            label="Phone number"
            placeholder="How to reach you?"
            name="phone"
            onChange={(e) => setData({ ...data, phone: e.target.value })}
          />
          <TextArea
            label="Message"
            placeholder="Tell us your story..."
            name="message"
            onChange={(e) => setData({ ...data, message: e.target.value })}
          />

          <Button
            className="Contact__send"
            label="Send"
            primary
            disabled={!valid}
            onClick={() => {
              axios
                .post('crypto_auth/send_contact_email.php', data)
                .then(() => {
                  onContactSent();
                  setError(null);
                  onClose();
                })
                .catch((err) => {
                  console.error(err);
                  setError(err);
                });
            }}
          ></Button>

          {error && (
            <p className="Contact_error">
              An error occured when sending your message. Please retry again.
            </p>
          )}
        </form>
      </section>
    </div>
  );
}

export default Contact;
