export const getCurrentPoint = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const lon = searchParams.get('lon');
  const lat = searchParams.get('lat');
  const zoom = searchParams.get('zoom');
  return [lon, lat, zoom];
};

export const isPointDefined = (coord) =>
  !!coord[0] && !!coord[1] && !isNaN(coord[0]) && !isNaN(coord[1]);
