import './SearchInput.css';

function SearchInput({ placeholder, onChange, className }) {
  return (
    <div className={`SearchInput ${className || ''}`}>
      <ion-icon class="SearchInput__icon" name="search"></ion-icon>
      <input
        className="SearchInput__content"
        placeholder={placeholder}
        type="search"
        onChange={onChange}
      ></input>
    </div>
  );
}

export default SearchInput;
