export const LOCAL_STORAGE = {
  PROFILE: 'x-profile',
  DISPLAY_INFO: 'x-display-info',
  COOKIES_USAGE: 'x-cookies-usage',
};

export const updateUserInfo = (userInfo) => {
  if (userInfo)
    localStorage.setItem(LOCAL_STORAGE.PROFILE, JSON.stringify(userInfo));
  else localStorage.removeItem(LOCAL_STORAGE.PROFILE);
};

export const getDisplayFilterMenu = () =>
  localStorage.getItem(LOCAL_STORAGE.DISPLAY_INFO);

export const updateDisplayFilterMenu = () =>
  localStorage.setItem(LOCAL_STORAGE.DISPLAY_INFO, 'done');

