import './Profile.css';
import Input from './Input';
import Button from './Button';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { getSignedNonce } from './accountUtils';
import Snackbar from './Snackbar';
import Signing from './Signing';
import { validateEmail, validateUrl } from './validationUtils';

const FORM_INITIAL_STATE = {
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  country_iso3166_2: '',
  time_zone: '',
  profile_picture: '',
};

function Profile({ userInfo, web3, account, onClose, onProfileUpdated }) {
  const [data, setData] = useState(FORM_INITIAL_STATE);
  const [errors, setErrors] = useState([]);
  const [edit, setEdit] = useState(false);
  const [signing, setSigning] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const profileInput = useRef(null);

  useEffect(() => {
    if (userInfo) setData(userInfo);
    else setData(FORM_INITIAL_STATE);
  }, [userInfo]);

  useEffect(() => {
    const errors = [];

    if (!data.username || !data.username.trim()) errors.push('username');
    if (!data.email || !validateEmail(data.email.trim())) errors.push('email');
    if (!(!data.instagram || validateUrl(data.instagram)))
      errors.push('instagram');
    if (!(!data.twitter || validateUrl(data.twitter))) errors.push('twitter');
    if (!(!data.linkedin || validateUrl(data.linkedin)))
      errors.push('linkedin');
    if (!(!data.youtube || validateUrl(data.youtube))) errors.push('youtube');

    setErrors(errors);
  }, [data]);

  const handleSaveUserInfo = async () => {
    if (!edit) {
      setEdit(!edit);
      return;
    }

    setSigning(true);
    const signedNonce = await getSignedNonce(web3, account);
    setSigning(false);

    setUpdating(true);
    axios
      .post('crypto_auth/update_profile.php', {
        ...data,
        address: account,
        signed: signedNonce,
      })
      .then(() => {
        setEdit(!edit);
        setUpdating(false);
        onClose();
        onProfileUpdated(data);
      })
      .catch((e) => {
        const message = e.response.data.error;
        if (message) {
          setSnackbar({
            type: 'error',
            text: message,
          });
        } else {
          setSnackbar({
            type: 'error',
            text: 'Error occured when updating your profile info.',
          });
        }
        setUpdating(false);
        setTimeout(() => setSnackbar(null), 4000);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const size = (file.size / 1024 / 1024).toFixed(4);
    if (size > 2) {
      setSnackbar({
        type: 'error',
        text: 'Files more than 2Mo are not allowed.',
      });
      setTimeout(() => {
        setSnackbar(null);
      }, 4000);
      return false;
    }

    getBase64(file)
      .then((result) => {
        file['base64'] = result;
        setData({ ...data, profile_picture: result });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {signing && <Signing />}

      <div className="Profile">
        <form className="Profile__section" onSubmit={(e) => e.preventDefault()}>
          <button className="Profile__section-close" onClick={onClose}>
            <img src="close-large.svg" alt="Close" />
          </button>
          <header className="Profile__header">
            {edit && (
              <img
                className="Profile__header-image-edit"
                src="edit-mini.svg"
                alt="Edit"
                onClick={() => edit && profileInput.current.click()}
              />
            )}
            <img
              className={`Profile__header-image ${
                edit ? 'Profile__header-image--editable' : ''
              }`}
              src={
                !(data && data.profile_picture)
                  ? 'profile.svg'
                  : data.profile_picture
              }
              alt="Profile"
              onClick={() => edit && profileInput.current.click()}
            />

            {edit && (
              <input
                ref={profileInput}
                className="Profile__header-image-input"
                type="file"
                accept="image/*"
                name="profile"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
              />
            )}
            <Input
              className={!edit ? 'Profile__header-username' : ''}
              invalid={edit && errors.includes('username')}
              placeholder="Username *"
              defaultValue={data.username}
              readonly={!edit}
              onChange={(e) => setData({ ...data, username: e.target.value })}
            />
          </header>
          <hr />
          <div className="Profile__content">
            <Input
              label="First name"
              defaultValue={data.first_name}
              readonly={!edit}
              onChange={(e) => setData({ ...data, first_name: e.target.value })}
            />
            <Input
              label="Last name"
              defaultValue={data.last_name}
              readonly={!edit}
              onChange={(e) => setData({ ...data, last_name: e.target.value })}
            />
            <Input
              label="Email *"
              invalid={edit && errors.includes('email')}
              defaultValue={data.email}
              readonly={!edit}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <Input
              label="Phone number"
              defaultValue={data.phone}
              readonly={!edit}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
            />
          </div>
          <hr />
          <div className="Profile__content">
            <Input
              label="Instagram profile"
              invalid={edit && errors.includes('instagram')}
              defaultValue={data.instagram}
              readonly={!edit}
              onChange={(e) => setData({ ...data, instagram: e.target.value })}
            />
            <Input
              label="Twitter profile"
              invalid={edit && errors.includes('twitter')}
              defaultValue={data.twitter}
              readonly={!edit}
              onChange={(e) => setData({ ...data, twitter: e.target.value })}
            />
            <Input
              label="LinkedIn profile"
              invalid={edit && errors.includes('linkedin')}
              defaultValue={data.linkedin}
              readonly={!edit}
              onChange={(e) => setData({ ...data, linkedin: e.target.value })}
            />
            <Input
              label="Youtube profile"
              invalid={edit && errors.includes('youtube')}
              defaultValue={data.youtube}
              readonly={!edit}
              onChange={(e) => setData({ ...data, youtube: e.target.value })}
            />
          </div>
          {edit && (
            <Button
              primary
              label={updating ? 'Saving...' : 'Save'}
              className="Profile__edit"
              disabled={updating || !(errors && errors.length === 0)}
              onClick={handleSaveUserInfo}
            />
          )}
          {!edit && (
            <Button
              primary
              label="Edit profile"
              className="Profile__edit"
              onClick={handleSaveUserInfo}
            />
          )}
        </form>
      </div>

      {snackbar && <Snackbar type={snackbar.type} text={snackbar.text} />}
    </>
  );
}

export default Profile;
