import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { LoadingIndicator } from "./Loading";
import "./Mailbox.css";
import NewMessage from "./NewMessage";
import Reply from "./Reply";

const TEST = [
  {
    message_id: "50",
    send_date: "2022-06-25 14:04:45",
    subject: "",
    body: "oui",
    read_status: false,
    read_date: null,
    conversation_id: "43",
    sender_wallet: "0x3f2119c50ae2236b16971e055b2062aa0adc9334",
    nft_request_id: null,
    recipient_wallet: "0x0f4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    recipient_username: "rina",
    sender_username: "jbove",
  },
];

const TEST_SENT = [
  {
    message_id: "43",
    send_date: "2022-06-09 11:49:08",
    subject: "",
    body: "hello Bove!",
    read_status: true,
    read_date: "2022-06-25 14:59:33",
    conversation_id: "43",
    sender_wallet: "0x0f4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    nft_request_id: null,
    recipient_wallet: "0x3f2119c50ae2236b16971e055b2062aa0adc9334",
    recipient_username: "jbove",
    sender_username: "rina",
  },
  {
    message_id: "44",
    send_date: "2022-06-09 11:49:18",
    subject: "",
    body: "ca va?",
    read_status: true,
    read_date: "2022-06-25 14:59:33",
    conversation_id: "43",
    sender_wallet: "0x0f4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    nft_request_id: null,
    recipient_wallet: "0x3f2119c50ae2236b16971e055b2062aa0adc9334",
    recipient_username: "jbove",
    sender_username: "rina",
  },
  {
    message_id: "45",
    send_date: "2022-06-09 11:55:48",
    subject: "",
    body: "hello, do you trade?",
    read_status: false,
    read_date: null,
    conversation_id: "45",
    sender_wallet: "0x0f4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    nft_request_id: null,
    recipient_wallet: "0xf4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    recipient_username: null,
    sender_username: "rina",
  },
  {
    message_id: "46",
    send_date: "2022-06-09 17:01:28",
    subject: "",
    body: "hey",
    read_status: true,
    read_date: "2022-06-25 14:59:33",
    conversation_id: "43",
    sender_wallet: "0x0f4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    nft_request_id: null,
    recipient_wallet: "0x3f2119c50ae2236b16971e055b2062aa0adc9334",
    recipient_username: "jbove",
    sender_username: "rina",
  },
  {
    message_id: "47",
    send_date: "2022-06-09 17:06:19",
    subject: "",
    body: "yes",
    read_status: false,
    read_date: null,
    conversation_id: "45",
    sender_wallet: "0x0f4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    nft_request_id: null,
    recipient_wallet: "0xf4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    recipient_username: null,
    sender_username: "rina",
  },
  {
    message_id: "48",
    send_date: "2022-06-09 19:27:12",
    subject: "",
    body: "tu recois?",
    read_status: true,
    read_date: "2022-06-25 14:59:34",
    conversation_id: "43",
    sender_wallet: "0x0f4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    nft_request_id: null,
    recipient_wallet: "0x3f2119c50ae2236b16971e055b2062aa0adc9334",
    recipient_username: "jbove",
    sender_username: "rina",
  },
  {
    message_id: "49",
    send_date: "2022-06-09 19:29:44",
    subject: "",
    body: "hello, do you trade?",
    read_status: true,
    read_date: "2022-06-25 14:59:36",
    conversation_id: "49",
    sender_wallet: "0x0f4ba3ec058e6d16ab06e002ec437b9dae6bf91e",
    nft_request_id: null,
    recipient_wallet: "0x3f2119c50ae2236b16971e055b2062aa0adc9334",
    recipient_username: "jbove",
    sender_username: "rina",
  },
];

const DEFAULT_USERNAME = "tilia.earth user";

const reduceMessages = (mixedData, account) => {
  const sortedMessages = mixedData.sort((m1, m2) =>
    m1.send_date < m2.send_date ? 1 : -1,
  );

  const result = sortedMessages.reduce((acc, m) => {
    let withWho, withWho_username;
    if (m.sender_wallet.toLowerCase() === account.toLowerCase()) {
      withWho = m.recipient_wallet.toLowerCase();
      withWho_username = m.recipient_username;
    } else if (m.recipient_wallet.toLowerCase() === account.toLowerCase()) {
      withWho = m.sender_wallet.toLowerCase();
      withWho_username = m.sender_username;
    }
    if (m.conversation_id) {
      const found = acc.find(
        (ms) =>
          ms.conversation_id === m.conversation_id && ms.withWho === withWho,
      );
      if (found) {
        found.messages = [...found.messages, m];
        return acc;
      } else {
        return [
          ...acc,
          {
            withWho,
            conversation_id: m.conversation_id,
            messages: [m],
            withWho_username,
          },
        ];
      }
    }
    return [...acc, { withWho, withWho_username, messages: [m] }];
  }, []);

  return result;
};

function Mailbox({ account, onClose, onGetSignedNonce }) {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(null);
  const [reload, setReload] = useState(false);
  const [signing, setSigning] = useState(false);
  const refSignature = useRef(null);
  const [profiles, setProfiles] = useState([]);
  const [openNewMessage, setOpenNewMessage] = useState(false);
  const [replyId, setReplyId] = useState(uuidv4());
  const [openFirstMessage, setOpenFirstMessage] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    openMessage &&
      messagesEndRef.current &&
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => setTimeout(() => scrollToBottom(), 100), [openMessage]);

  useEffect(() => {
    const getMessages = () => {
      !openMessage && setLoading(true);
      Promise.all([
        axios.post("crypto_auth/get_user_messages.php", {
          mode: null,
          address: account,
          signed: refSignature.current,
        }),
        axios.post("crypto_auth/get_user_messages.php", {
          mode: "sent",
          address: account,
          signed: refSignature.current,
        }),
      ])
        .then(([data1, data2]) => {
          const mixedData = [...data1.data, ...data2.data];
          const result = reduceMessages(mixedData, account);
          setMessages(result);

          if (result && result.length > 0) {
            const message = result[0];
            setOpenMessage(message);
          }

          axios
            .get("crypto_auth/get_many_profiles.php", {
              addresses: result.map((r) => r.withWho),
            })
            .then(({ data }) => {
              setProfiles(data);
            });
          setLoading(false);

          setReplyId(uuidv4());
          setTimeout(() => scrollToBottom(), 100);
        }) // TODO: TEST to remove
        .catch((err) => {
          console.error(err);

          /*
          const mixedData = [...TEST, ...TEST_SENT];
          const result = reduceMessages(mixedData, account);
          console.log(result);
          setMessages(result);
          */

          setMessages([]);
          setLoading(false);
        });
    };

    const getSignedNonce = async () => {
      refSignature.current = await onGetSignedNonce(account);
    };

    // TODO: remove && false
    if (!refSignature.current) {
      setSigning(true);
      getSignedNonce().then(() => {
        setSigning(false);
        getMessages();
      });
    } else {
      getMessages();
    }
  }, [account, onGetSignedNonce, reload]);

  useEffect(() => {
    if (messages && messages.length > 0 && openFirstMessage) {
      const message = messages.find(
        (m) => m.conversation_id === openFirstMessage,
      );
      if (message) {
        setOpenMessage(message);
      }
    }
  }, [openFirstMessage, messages]);

  const handleOpenMessage = (message) => {
    setOpenNewMessage(false);
    setOpenMessage(message);
    const ids = message.messages
      .sort((m1, m2) => (m1.send_date < m2.send_date ? -1 : 1))
      .map((m) => m.message_id);
    for (let i = 0; i < ids.length; i++) {
      axios.post("crypto_auth/set_user_message_read.php", {
        message_id: ids[i],
        address: account,
        signed: refSignature.current,
      });
    }
  };

  const getProfilePicture = (message) => {
    const wallet = message.withWho_username;
    const profile = profiles.find((p) => p.wallet_address === wallet);
    if (profile && profile.profile_picture) {
      return profile.profile_picture;
    } else {
      return "profile.svg";
    }
  };

  const foundMessage = openMessage
    ? messages.find((m) => m.conversation_id === openMessage.conversation_id)
    : null;
  const currentMessages = foundMessage ? foundMessage.messages : [];

  return (
    <>
      {!signing && (
        <div className="Mailbox__container">
          {loading && <LoadingIndicator />}
          {!loading && (
            <article className="Mailbox">
              <button className="Mailbox__header-close" onClick={onClose}>
                <ion-icon name="close-outline" size="large"></ion-icon>
              </button>
              <aside className="Mailbox__header">
                <div>
                  <label className="Mailbox__header-properties">
                    <span className="Mailbox__header-properties-text">
                      You have
                    </span>
                    <span className="Mailbox__header-properties-digit">
                      {messages.length}
                    </span>
                    <span className="Mailbox__header-properties-text">
                      {messages.length > 1 ? "discussions" : "discussion"}
                    </span>
                  </label>
                  <button
                    className="Mailbox__header-new"
                    onClick={() => {
                      setOpenMessage(null);
                      setOpenNewMessage(true);
                    }}
                  >
                    <ion-icon name="create-outline" size="small"></ion-icon>
                  </button>
                </div>
                <ul className="Mailbox__header-messages">
                  {messages.map((message) => (
                    <li
                      className={`Mailbox__header-messages-item ${
                        openMessage &&
                        message.withWho === openMessage.withWho &&
                        message.conversation_id === openMessage.conversation_id
                          ? "selected"
                          : ""
                      }`}
                      key={`${message.withWho}-${message.conversation_id}`}
                      onClick={() => handleOpenMessage(message)}
                    >
                      <img
                        className="Mailbox__header-messages-item-image"
                        src={getProfilePicture(message)}
                        alt={message.withWho_username || DEFAULT_USERNAME}
                      />
                      <label className="Mailbox__header-messages-item-name">
                        {message.withWho_username || DEFAULT_USERNAME}
                        {/*<span className="Mailbox__header-messages-item-date">
                          {new Date(message.send_date).toLocaleString()}
                    </span>*/}
                      </label>
                      {message.messages && message.messages.length > 0 && (
                        <label className="Mailbox__header-messages-item-nb">
                          {message.messages.length}
                        </label>
                      )}
                    </li>
                  ))}
                </ul>
              </aside>

              <div className="Mailbox__items">
                {!openNewMessage && messages.length <= 0 && (
                  <p className="Mailbox_no-messages">No messages to display.</p>
                )}

                {openMessage && (
                  <header className="Mailbox__items-header">
                    <img
                      className="Mailbox__item-image"
                      src={getProfilePicture(openMessage)}
                      alt={openMessage.withWho_username || DEFAULT_USERNAME}
                    />
                    <label className="Mailbox__items-header-name">
                      {openMessage.withWho_username || DEFAULT_USERNAME}
                    </label>

                    {/*<button className="Mailbox__items-header-delete">
                      <ion-icon name="trash-outline" size="small"></ion-icon>
                </button>*/}
                  </header>
                )}

                {openNewMessage && (
                  <NewMessage
                    account={account}
                    signed={refSignature.current}
                    onMessageSent={(conversation_id) => {
                      setOpenNewMessage(false);
                      setReload(!reload);
                      setTimeout(
                        () => setOpenFirstMessage(conversation_id),
                        [100],
                      );
                    }}
                  />
                )}

                {currentMessages && currentMessages.length > 0 && (
                  <>
                    <ul>
                      {currentMessages
                        .sort((m1, m2) =>
                          m1.send_date < m2.send_date ? -1 : 1,
                        )
                        .map((m) => (
                          <li
                            className={`Mailbox__item-message ${
                              m.sender_username === openMessage.withWho_username
                                ? "Mailbox__item-message-sender"
                                : "Mailbox__item-message-receiver"
                            }`}
                            key={`${m.message_id}-${m.conversation_id}`}
                          >
                            <p>
                              {m.body}
                              <span>
                                {new Date(m.send_date).toLocaleString()}
                              </span>
                            </p>
                          </li>
                        ))}
                      <li ref={messagesEndRef} />
                    </ul>
                    <Reply
                      replyId={replyId}
                      account={account}
                      signed={refSignature.current}
                      recipient={openMessage.withWho}
                      conversationId={openMessage.conversation_id}
                      onMessageSent={() => {
                        setReload(!reload);
                      }}
                    />
                  </>
                )}
              </div>
            </article>
          )}
        </div>
      )}
    </>
  );
}

export default Mailbox;
