import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from './Button';
import './Location.css';
import { calculatePosition } from './locationPositionUtils';

import Snackbar from './Snackbar';
import UpdateLocationDetails from './UpdateLocationDetails';
import useClickOutside from './useClickOutside';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { getOwnerOfNFT } from './nftUtils';
import axios from 'axios';
import TextArea from './TextArea';

function Location({
  location,
  account,
  ownedNFTs,
  onClose,
  onZoom,
  onGetSignedNonce,
  onFeatureUpdated,
  onResolveNFTs,
  onOpenOwner,
  onOpenBuy,
}) {
  const {
    name,
    contract,
    image_url,
    sold,
    nft_id,
    shopify_handle,
    custom_name,
    custom_link,
    coordinates,
    position,
    nft_request_id,
  } = location;
  const [openUpdateDetails, setOpenUpdateDetails] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [share, setShare] = useState(false);
  const [owner, setOwner] = useState(null);
  const [ownerInfo, setOwnerInfo] = useState({});
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, onClose);

  useEffect(() => {
    if (owner) {
      axios
        .get(`crypto_auth/get_profile.php?address=${owner}`)
        .then(({ data }) => {
          setOwnerInfo(data);
        })
        // to update
        .catch((err) => {
          console.error(err);
          setOwnerInfo({});
        });
    }
  }, [owner]);

  useEffect(() => {
    getOwnerOfNFT(contract, nft_id).then(async (owner) => {
      if (
        owner &&
        owner.owner !== '0x1c53f4d54597c3f896dd60e0e35bfc74e37c33b6'
      ) {
        setOwner(owner.owner);
      } else {
        setOwner(null);
      }
    });
  }, [contract, nft_id, onResolveNFTs]);

  const handleBuy = () => {
    window.open(`https://tiliaverse.com/products/${shopify_handle}`, '_blank');
  };

  const handleMakeOffer = () => {
    window.open(
      `https://opensea.io/assets/matic/${contract}/${nft_id}`,
      '_blank'
    );
  };

  const shareUrl = `${window.location.origin}/?lon=${coordinates[0]}&lat=${coordinates[1]}`;

  const handleCopyUrlToClipboard = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(shareUrl);
    } else {
      document.execCommand('copy', true, shareUrl);
    }
    setSnackbar({ type: 'success', text: 'Url copied to your clipboard!' });
    setTimeout(() => {
      setSnackbar(null);
    }, 3000);
  };

  const handleSendMessage = () => {
    onGetSignedNonce(account)
      .then((data) => {
        axios
          .post('crypto_auth/send_user_message.php', {
            nft_request_id,
            recipient: owner,
            body: message,
            address: account,
            signed: data,
          })
          .then(() => {
            setOpenMessage(false);
            setMessage('');
            setSnackbar({
              type: 'success',
              text: 'Your message is successfuly sent!',
            });
            setTimeout(() => {
              setSnackbar(null);
            }, 3000);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const isOwner = ownedNFTs && ownedNFTs.includes(nft_id);

  const [x, y] = calculatePosition(position, !sold);

  return (
    <>
      <article style={{ left: `${x}px`, top: `${y}px` }} className="Location">
        <button className="Location__header-close" onClick={onClose}>
          <img src="close.svg" alt="Close" />
        </button>
        {sold && owner && (
          <header className="Location__header">
            <button
              className="Location__header-image-button"
              onClick={sold ? () => onOpenOwner(account) : () => {}}
            >
              <img
                className="Location__header-image"
                src={
                  ownerInfo && ownerInfo.profile_picture
                    ? ownerInfo.profile_picture
                    : 'profile.svg'
                }
                alt="Owner"
              />
            </button>
          </header>
        )}

        <img
          className={`Location__location-image ${
            sold ? '' : 'Location__location-image--rounded'
          }`}
          src={image_url}
          alt={name}
        />

        {isOwner && sold && (
          <div className="Location__location-user">
            <p
              className={`Location__location-user-pseudo ${
                custom_name ? '' : 'greyed'
              }`}
            >
              {custom_name || 'Owner'}
            </p>
            {!custom_link && (
              <span className="Location__location-user-link greyed">Link</span>
            )}
            {custom_link && (
              <a
                className="Location__location-user-link"
                href={custom_link}
                target="_blank"
                rel="noreferrer"
              >
                <span>{custom_link}</span>
              </a>
            )}
          </div>
        )}
        {!isOwner && sold && (custom_name || custom_link) && (
          <div className="Location__location-user">
            {custom_name && (
              <p className="Location__location-user-pseudo">{custom_name}</p>
            )}
            {custom_link && (
              <a
                target="_blank"
                rel="noreferrer"
                className="Location__location-user-link"
                href={custom_link}
              >
                <span>{custom_link}</span>
              </a>
            )}
          </div>
        )}

        {sold && (custom_name || custom_link || isOwner) && <hr />}

        <div className="Location__location-address">
          <span>Address</span>
          <p className="Location__location-address-content">{name}</p>
        </div>

        <ul className="Location__location-actions">
          {sold && (
            <li className="Location__location-actions-item">
              <button
                className="Location__location-actions-item-button"
                onClick={() => {
                  setShare(false);
                  setOpenMessage(!openMessage);
                }}
                alt="Send message"
              >
                <img src="chatbox.svg" alt="Send message" />
              </button>
            </li>
          )}
          {!sold && (
            <li className="Location__location-actions-item">
              <button
                className="Location__location-actions-item-button Location__location-actions-item-button-flat"
                onClick={() => onOpenBuy(nft_id)}
                alt="Buy on Simplex"
              >
                Buy on Simplex
              </button>
            </li>
          )}
          {sold && owner && (
            <li className="Location__location-actions-item">
              <button
                className="Location__location-actions-item-button"
                onClick={() => onOpenOwner(owner)}
                alt="Owner profile"
              >
                <img src="user-profile.svg" alt="Owner profile" />
              </button>
            </li>
          )}
          <li className="Location__location-actions-item">
            <button
              className="Location__location-actions-item-button"
              onClick={handleCopyUrlToClipboard}
              alt="Copy Url"
            >
              <img src="copy.svg" alt="Copy Url" />
            </button>
          </li>
          <li className="Location__location-actions-item">
            <button
              className="Location__location-actions-item-button"
              onClick={() => {
                setOpenMessage(false);
                setShare(!share);
              }}
              alt="Share"
            >
              <img src={share ? 'share-filled.svg' : 'share.svg'} alt="Share" />
            </button>
          </li>
          <li className="Location__location-actions-item">
            <button
              className="Location__location-actions-item-button"
              onClick={() => onZoom(nft_id)}
              alt="Zoom"
            >
              <img src="contract.svg" alt="Zoom" />
            </button>
          </li>
        </ul>

        {share && (
          <ul className="Location__location-social">
            <li>
              <FacebookShareButton url={shareUrl} quote={name}>
                <FacebookIcon
                  size={32}
                  bgStyle={{ fill: '#fff' }}
                  iconFillColor="#7c5050"
                />
              </FacebookShareButton>
            </li>
            <li>
              <TwitterShareButton url={shareUrl} title={name}>
                <TwitterIcon
                  size={32}
                  bgStyle={{ fill: '#fff' }}
                  iconFillColor="#7c5050"
                />
              </TwitterShareButton>
            </li>
            <li>
              <WhatsappShareButton url={shareUrl} title={name} separator=":: ">
                <WhatsappIcon
                  size={32}
                  bgStyle={{ fill: '#fff' }}
                  iconFillColor="#7c5050"
                />
              </WhatsappShareButton>
            </li>
            <li>
              <LinkedinShareButton url={shareUrl} s>
                <LinkedinIcon
                  size={32}
                  bgStyle={{ fill: '#fff' }}
                  iconFillColor="#7c5050"
                />
              </LinkedinShareButton>
            </li>
            <li>
              <EmailShareButton
                url=""
                subject="Welcome on Tilia.Earth Metaverse !"
                body={`Please discover this virtual property localized ${name} : ${shareUrl}`}
              >
                <EmailIcon
                  size={32}
                  bgStyle={{ fill: '#fff' }}
                  iconFillColor="#7c5050"
                />
              </EmailShareButton>
            </li>
          </ul>
        )}

        {openMessage && (
          <div className="Location__message">
            <TextArea
              className="Location__message-message"
              label="Message"
              name="message"
              rows="2"
              onChange={(e) => setMessage(e.target.value)}
            />
            <ul className="Location__message-actions">
              <li></li>
              <li>
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSendMessage();
                  }}
                  disabled={!message || !message.trim()}
                >
                  <ion-icon name="send" size="small"></ion-icon>
                </button>
              </li>
            </ul>
          </div>
        )}

        <footer className="Location__footer">
          {isOwner && (
            <Button
              mini
              primary
              label="Update Details"
              onClick={() => setOpenUpdateDetails(!openUpdateDetails)}
            ></Button>
          )}
          {!isOwner &&
            (sold ? (
              <Button
                mini
                primary
                label="Make Offer"
                onClick={() => handleMakeOffer()}
              ></Button>
            ) : (
              <Button
                mini
                primary
                label="Buy It"
                onClick={() => handleBuy()}
              ></Button>
            ))}
        </footer>
      </article>
      {openUpdateDetails && account && (
        <UpdateLocationDetails
          account={account}
          contract={contract}
          nftId={nft_id}
          onClose={() => setOpenUpdateDetails(!openUpdateDetails)}
          onSnackbar={(snackbar) => {
            setSnackbar(snackbar);
            setTimeout(() => {
              setSnackbar(null);
            }, 4000);
          }}
          details={{
            custom_name: custom_name || '',
            custom_link: custom_link || '',
          }}
          onGetSignedNonce={onGetSignedNonce}
          onFeatureUpdated={onFeatureUpdated}
        />
      )}

      {snackbar && (
        <Snackbar type={snackbar.type} text={snackbar.text} id={uuidv4()} />
      )}
    </>
  );
}

export default Location;
