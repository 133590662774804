import { useDebounce } from "@react-hook/debounce";
import axios from "axios";
import { useEffect, useState } from "react";
import Input from "./Input";
import TextArea from "./TextArea";

import "./NewMessage.css";
import Receiver from "./Receiver";

const NewMessage = ({ account, signed, onMessageSent }) => {
  const [searchTerm, setSearchTerm] = useDebounce("", 1000);
  const [receiver, setReceiver] = useState(null);
  const [users, setUsers] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // when term changes
  useEffect(() => {
    setLoading(true);
    axios
      .get(`crypto_auth/search_pseudo.php?username=${searchTerm}`)
      .then(({ data }) => {
        setUsers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        // TODO: to comment
        setUsers([
          /*{
            username: 'jbove',
            wallet_address: '123323',
          },
          {
            username: 'jb',
            wallet_address: '123324',
          },*/
        ]);
      });
  }, [searchTerm]);

  const handleSearchUsername = (text) => setSearchTerm(text);

  const handleSendNewMessage = (receiver, message) => {
    axios
      .post("crypto_auth/send_user_message.php", {
        recipient: receiver,
        subject: "",
        body: message,
        signed,
        address: account,
      })
      .then(({ data }) => {
        setReceiver(null);
        setUsers([]);
        setNewMessage("");

        onMessageSent(`${data.conversation_id}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="NewMessage">
      <header className="NewMessage__header">
        <h1>New message</h1>
        {!receiver && (
          <Input
            label="To"
            onChange={(event) => handleSearchUsername(event.target.value)}
          ></Input>
        )}
        {!receiver && users.length > 0 && !loading && (
          <ul className="Search__result-users">
            {users.map((u) => (
              <li key={u.username}>
                <button
                  className="Search__result-users-item-button"
                  onClick={() => setReceiver(u)}
                >
                  {u.username}
                </button>
              </li>
            ))}
          </ul>
        )}
        {loading && (
          <button className="Search__result-users-item-button">
            &nbsp;&nbsp;&nbsp;...&nbsp;&nbsp;&nbsp;
          </button>
        )}
        {receiver && (
          <Receiver receiver={receiver} onClear={() => setReceiver(null)} />
        )}
      </header>
      <TextArea
        label="Message"
        name="message"
        rows="3"
        onChange={(e) => setNewMessage(e.target.value)}
      />
      <div className="NewMessage__send">
        <button
          onClick={(event) => {
            event.stopPropagation();
            handleSendNewMessage(receiver.wallet_address, newMessage);
          }}
          disabled={!receiver || !newMessage || !newMessage.trim()}
        >
          <ion-icon name="send" size="small"></ion-icon>
        </button>
      </div>
    </div>
  );
};

export default NewMessage;
