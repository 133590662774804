import './TextArea.css';

function TextArea({ label, name, placeholder, onChange, className, rows = 4 }) {
  return (
    <div className={`TextArea ${className || ''}`}>
      <label className="TextArea__label" htmlFor={name}>
        {label}
      </label>
      <textarea
        id={name}
        rows={rows}
        className="TextArea__content"
        placeholder={placeholder}
        onChange={onChange}
      ></textarea>
    </div>
  );
}

export default TextArea;
