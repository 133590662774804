import './Input.css';

function Input({
  label,
  name,
  placeholder,
  onChange,
  className,
  defaultValue,
  readonly,
  invalid,
  type,
}) {
  return (
    <div className={`Input ${className || ''}`}>
      <label className="Input__label" htmlFor={name}>
        {label}
      </label>
      <input
        id={name}
        className={`Input__content ${invalid ? 'Input__content--error' : ''}`}
        placeholder={placeholder}
        onChange={onChange}
        value={defaultValue}
        readOnly={readonly}
        disabled={readonly}
        type={type || 'text'}
      ></input>
    </div>
  );
}

export default Input;
