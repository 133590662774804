import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Loading from './Loading';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import PageNotFound from './PageNotFound';

const root = document.getElementById('root');
const loading = document.getElementById('loading');

setTimeout(() => {
  loading.remove();
}, 5000);

ReactDOM.render(<Loading />, loading);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
