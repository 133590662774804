import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import About from './About';
import Contact from './Contact';
import './Menu.css';
import useClickOutside from './useClickOutside';

function Menu({
  connected,
  userInfo,
  onConnectWallet,
  onLogout,
  onContactSent,
  onOpenProfile,
  onOpenOwner,
  onOpenMailbox,
}) {
  const [toggle, setToggle] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [nbUnread, setNbUnread] = useState(0);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setToggle(false));

  useEffect(() => {
    axios
      .post('crypto_auth/get_user_unread_messages.php', { address: connected })
      .then(({ data }) => {
        setNbUnread(data.unread);
      })
      .catch((err) => {
        console.error(err);
        setNbUnread(0);
      });
  }, [connected, toggle]);

  return (
    <>
      <div className="Menu" ref={wrapperRef}>
        {nbUnread > 0 && <span className="Menu__badge">{nbUnread}</span>}

        <button className="Menu__profile" onClick={() => setToggle(!toggle)}>
          <img
            className="Menu__profile-image"
            src={
              userInfo && userInfo.profile_picture
                ? userInfo.profile_picture
                : 'profile.svg'
            }
            alt="Profile"
          />
        </button>

        {toggle && (
          <ul className="Menu__items">
            {!connected && (
              <>
                <li className="Menu__item Menu__item--highlight">
                  <button
                    onClick={() => {
                      onConnectWallet();
                      setToggle(!toggle);
                    }}
                  >
                    <ion-icon name="enter-outline" size="small"></ion-icon>
                    Connect your wallet
                  </button>
                </li>
                <li className="Menu__item Menu__item--divider"></li>
              </>
            )}
            {connected && (
              <>
                <li className="Menu__item">
                  <button
                    onClick={() => {
                      onOpenProfile();
                      setToggle(!toggle);
                    }}
                  >
                    <img src="user-menu.svg" alt="Marketplace" />
                    My profile
                  </button>
                </li>
                <li className="Menu__item">
                  <button
                    onClick={() => {
                      onOpenOwner(connected);
                      setToggle(!toggle);
                    }}
                  >
                    <img src="flats.svg" alt="Marketplace" />
                    My properties
                  </button>
                </li>
                <li className="Menu__item">
                  <button
                    onClick={() => {
                      onOpenMailbox();
                      setToggle(!toggle);
                    }}
                  >
                    <ion-icon name="mail" size="small"></ion-icon>
                    Mailbox
                    {nbUnread > 0 && (
                      <span className="Menu__badge Menu__badge--item">
                        {nbUnread}
                      </span>
                    )}
                  </button>
                </li>
                <li className="Menu__item Menu__item--divider"></li>
              </>
            )}
            <li className="Menu__item">
              <button
                onClick={() => window.open(`https://tiliaverse.com`, '_blank')}
              >
                <img src="marketplace.svg" alt="Marketplace" />
                Marketplace - Tiliaverse
              </button>
            </li>
            <li className="Menu__item Menu__item--divider"></li>
            <li className="Menu__item">
              <button
                onClick={() => {
                  setOpenContact(true);
                  setToggle(false);
                  //window.open('https://tiliaverse.com/pages/contact', '_blank');
                }}
              >
                <img src="help.svg" alt="Marketplace" />
                Contact support
              </button>
            </li>
            <li className="Menu__item">
              <button
                onClick={() => {
                  setOpenAbout(true);
                  setToggle(false);
                }}
              >
                <ion-icon name="information" size="small"></ion-icon>
                About Tilia.Earth
              </button>
            </li>
            {connected && (
              <>
                <li className="Menu__item Menu__item--divider"></li>
                <li className="Menu__item">
                  <button
                    onClick={() => {
                      onLogout();
                      setToggle(!toggle);
                    }}
                  >
                    <ion-icon name="exit-outline" size="small"></ion-icon>
                    Log out
                  </button>
                </li>
              </>
            )}
          </ul>
        )}
      </div>

      {openAbout && <About onClose={() => setOpenAbout(false)} />}
      {openContact && (
        <Contact
          onClose={() => setOpenContact(false)}
          onContactSent={onContactSent}
        />
      )}
    </>
  );
}

export default Menu;
