import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { LoadingIndicator } from './Loading';
import { getOwnedNFTs } from './nftUtils';
import './Owner.css';
import useClickOutside from './useClickOutside';

function Owner({ account, onClose, onZoom, onResolveNFTs }) {
  const [ownerInfo, setOwnerInfo] = useState({});
  const [ownerProperties, setOwnerProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, onClose);

  useEffect(() => {
    axios
      .get(`crypto_auth/get_profile.php?address=${account}`)
      .then(({ data }) => {
        setOwnerInfo(data);
      })
      // to update
      .catch((err) => {
        console.error(err);
        setOwnerInfo({});
      });
    getOwnedNFTs(account)
      .then((ownerNFTs) => {
        setOwnerProperties(onResolveNFTs(ownerNFTs));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [account, onResolveNFTs]);

  const { profile_picture, username, instagram, twitter, linkedin, youtube } =
    ownerInfo;

  return (
    <div className="Owner__container">
      {loading && <LoadingIndicator />}
      {!loading && (
        <article ref={wrapperRef} className="Owner">
          <button className="Owner__header-close" onClick={onClose}>
            <img src="close.svg" alt="Close" />
          </button>
          <header className="Owner__header">
            <img
              className="Owner__header-image"
              src={profile_picture ? profile_picture : 'profile.svg'}
              alt="Profile"
            />
            <div className="Owner__header-user">
              <p className="Owner__header-user-pseudo">{username}</p>
              <span className="Owner__header-user-address">{account}</span>
              <ul className="Owner__header-user-social">
                {instagram && (
                  <li>
                    <a href={instagram} target="_blank" rel="noreferrer">
                      <ion-icon name="logo-instagram" size="small"></ion-icon>
                    </a>
                  </li>
                )}
                {twitter && (
                  <li>
                    <a href={twitter} target="_blank" rel="noreferrer">
                      <ion-icon name="logo-twitter" size="small"></ion-icon>
                    </a>
                  </li>
                )}
                {linkedin && (
                  <li>
                    <a href={linkedin} target="_blank" rel="noreferrer">
                      <ion-icon size="small" name="logo-linkedin"></ion-icon>
                    </a>
                  </li>
                )}
                {youtube && (
                  <li>
                    <a href={youtube} target="_blank" rel="noreferrer">
                      <ion-icon name="logo-youtube" size="small"></ion-icon>
                    </a>
                  </li>
                )}
              </ul>
            </div>

            <label className="Owner__header-properties">
              <span className="Owner__header-properties-text">Owner of</span>
              <span className="Owner__header-properties-digit">
                {ownerProperties.length}
              </span>
              <span className="Owner__header-properties-text">
                {ownerProperties.length > 1 ? 'properties' : 'property'}
              </span>
            </label>
          </header>

          <ul className="Owner__properties">
            {ownerProperties.map((p) => (
              <li
                key={p.properties.nft_id}
                className="Owner__properties-item"
                onClick={() => onZoom(p.properties.nft_id)}
              >
                <div className="Owner__properties-item-details">
                  {p.properties.custom_name && (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="Owner__properties-item-title"
                      href={p.properties.custom_link || '#'}
                    >
                      {p.properties.custom_name}
                    </a>
                  )}
                  {!p.properties.custom_name && p.properties.custom_link && (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="Owner__properties-item-title"
                      href={p.properties.custom_link}
                    >
                      {p.properties.custom_link}
                    </a>
                  )}
                  <p
                    className={`Owner__properties-item-address ${
                      !(p.properties.custom_name || p.properties.custom_link)
                        ? 'Owner__properties-item-address--long'
                        : ''
                    }`}
                  >
                    {p.properties.name}
                  </p>
                </div>
                <img
                  className="Owner__properties-item-map"
                  src={p.properties.image_url}
                  alt={p.properties.name}
                />
              </li>
            ))}
          </ul>
        </article>
      )}
    </div>
  );
}

export default Owner;
