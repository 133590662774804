import './FilterMenu.css';
import { useRef, useState } from 'react';
import useClickOutside from './useClickOutside';
import { Filter } from './Filter';
import { getDisplayFilterMenu, updateDisplayFilterMenu } from './storageUtils';

function FilterMenu({ filter, onFiltered }) {
  const [displayFilterMenu, setDisplayFilterMenu] = useState(
    !getDisplayFilterMenu()
  );

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setDisplayFilterMenu(false);
    updateDisplayFilterMenu();
  });

  return (
    <div className="FilterMenu" ref={wrapperRef}>
      {[Filter.PRIMARY, Filter.SECONDARY, Filter.YOURS].includes(filter) && (
        <div className="FilterMenu__badge"></div>
      )}
      <button
        className="FilterMenu__button"
        onClick={() => {
          setDisplayFilterMenu(!displayFilterMenu);
          updateDisplayFilterMenu();
        }}
      >
        <img src="filter.svg" alt="Map information" />
      </button>
      {displayFilterMenu && (
        <ul className="FilterMenu__items">
          <li>
            <div className="FilterMenu__item-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 512 512"
                fill="#7c5050"
              >
                <title>ionicons-v5-n</title>
                <circle cx="256" cy="192" r="32" />
                <path d="M256,32C167.78,32,96,100.65,96,185c0,40.17,18.31,93.59,54.42,158.78,29,52.34,62.55,99.67,80,123.22a31.75,31.75,0,0,0,51.22,0c17.42-23.55,51-70.88,80-123.22C397.69,278.61,416,225.19,416,185,416,100.65,344.22,32,256,32Zm0,224a64,64,0,1,1,64-64A64.07,64.07,0,0,1,256,256Z" />
              </svg>
            </div>
            <span onClick={() => onFiltered(Filter.PRIMARY)}>
              Primary Market
            </span>
            <button
              className="FilterMenu__item-check"
              onClick={() => onFiltered(Filter.PRIMARY)}
              alt="Check"
            >
              <img
                src={`${
                  filter === Filter.ALL
                    ? 'none-checked'
                    : filter === Filter.PRIMARY
                    ? 'checked'
                    : 'unchecked'
                }.svg`}
                alt="Check"
              />
            </button>
          </li>
          <li>
            <div className="FilterMenu__item-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 512 512"
                fill="#be9e9d"
              >
                <title>ionicons-v5-n</title>
                <circle cx="256" cy="192" r="32" />
                <path d="M256,32C167.78,32,96,100.65,96,185c0,40.17,18.31,93.59,54.42,158.78,29,52.34,62.55,99.67,80,123.22a31.75,31.75,0,0,0,51.22,0c17.42-23.55,51-70.88,80-123.22C397.69,278.61,416,225.19,416,185,416,100.65,344.22,32,256,32Zm0,224a64,64,0,1,1,64-64A64.07,64.07,0,0,1,256,256Z" />
              </svg>
            </div>
            <span onClick={() => onFiltered(Filter.SECONDARY)}>
              Secondary Market
            </span>
            <button
              className="FilterMenu__item-check"
              onClick={() => onFiltered(Filter.SECONDARY)}
              alt="Check"
            >
              <img
                src={`${
                  filter === Filter.ALL
                    ? 'none-checked'
                    : filter === Filter.SECONDARY
                    ? 'checked'
                    : 'unchecked'
                }.svg`}
                alt="Check"
              />
            </button>
          </li>
          <li>
            <div className="FilterMenu__item-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 512 512"
                fill="#012f04"
              >
                <title>ionicons-v5-n</title>
                <circle cx="256" cy="192" r="32" />
                <path d="M256,32C167.78,32,96,100.65,96,185c0,40.17,18.31,93.59,54.42,158.78,29,52.34,62.55,99.67,80,123.22a31.75,31.75,0,0,0,51.22,0c17.42-23.55,51-70.88,80-123.22C397.69,278.61,416,225.19,416,185,416,100.65,344.22,32,256,32Zm0,224a64,64,0,1,1,64-64A64.07,64.07,0,0,1,256,256Z" />
              </svg>
            </div>
            <span onClick={() => onFiltered(Filter.YOURS)}>
              Your properties
            </span>
            <button
              className="FilterMenu__item-check"
              onClick={() => onFiltered(Filter.YOURS)}
              alt="Check"
            >
              <img
                src={`${
                  filter === Filter.ALL
                    ? 'none-checked'
                    : filter === Filter.YOURS
                    ? 'checked'
                    : 'unchecked'
                }.svg`}
                alt="Check"
              />
            </button>
          </li>
          <li>
            <div className="FilterMenu__item-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 512 512"
                fill="#f46c62"
              >
                <title>ionicons-v5-n</title>
                <circle cx="256" cy="192" r="32" />
                <path d="M256,32C167.78,32,96,100.65,96,185c0,40.17,18.31,93.59,54.42,158.78,29,52.34,62.55,99.67,80,123.22a31.75,31.75,0,0,0,51.22,0c17.42-23.55,51-70.88,80-123.22C397.69,278.61,416,225.19,416,185,416,100.65,344.22,32,256,32Zm0,224a64,64,0,1,1,64-64A64.07,64.07,0,0,1,256,256Z" />
              </svg>
            </div>
            <span>Selected</span>
          </li>
          <li>
            <div className="FilterMenu__item-icon">
              <label className="Search__result-text-sign FilterMenu__item"></label>
            </div>
            <span>Group</span>
          </li>
          <li>
            <div className="FilterMenu__item-icon">
              <label className="Search__result-text-sign FilterMenu__item--owned"></label>
            </div>
            <span>Group with owned one(s)</span>
          </li>
          <li>
            <div className="FilterMenu__item-icon">
              <span className="Search__result-text-sign FilterMenu__item--search"></span>
            </div>
            <span>Search result</span>
          </li>
        </ul>
      )}
    </div>
  );
}

export default FilterMenu;
