import './Receiver.css';

const Receiver = ({ receiver, onClear }) => {
  return (
    <div className="Receiver">
      <label>{receiver.username}</label>
      <button onClick={onClear}>
        <img src="close.svg" alt="Remove" />
      </button>
    </div>
  );
};

export default Receiver;
