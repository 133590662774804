function contains(a, b) {
  return !(b.x1 < a.x1 || b.y1 < a.y1 || b.x2 > a.x2 || b.y2 > a.y2);
}

export const calculatePosition = ({ x, y }, small) => {
  const { innerWidth: width, innerHeight: height } = window;

  const containerDim = { width: 300, height: small ? 360 : 460 };

  const position = {
    top: [x - containerDim.width / 2 - 32, y - containerDim.height],
    bottom: [x - containerDim.width / 2 - 32, y + 32 + 32],
    right: [x, y - containerDim.height / 2 + 16],
    left: [x - containerDim.width - 32 - 32, y - containerDim.height / 2 + 16],
  };

  const windowRect = { x1: 0, y1: 0, x2: width, y2: height };

  const results = {
    top: contains(windowRect, {
      x1: position.top[0],
      y1: position.top[1],
      x2: position.top[0] + containerDim.width,
      y2: position.top[1] + containerDim.height,
    }),
    right: contains(windowRect, {
      x1: position.right[0],
      y1: position.right[1],
      x2: position.right[0] + containerDim.width,
      y2: position.right[1] + containerDim.height,
    }),
    bottom: contains(windowRect, {
      x1: position.bottom[0],
      y1: position.bottom[1],
      x2: position.bottom[0] + containerDim.width,
      y2: position.bottom[1] + containerDim.height,
    }),
    left: contains(windowRect, {
      x1: position.left[0],
      y1: position.left[1],
      x2: position.left[0] + containerDim.width,
      y2: position.left[1] + containerDim.height,
    }),
  };

  if (results.right) return position.right;
  if (results.bottom) return position.bottom;
  if (results.left) return position.left;
  return position.top;
};
