import { useEffect, useState } from 'react';
import './Snackbar.css';

function Snackbar({ type, text, id }) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
    setTimeout(() => setOpen(false), 4000);
  }, [id]);
  if (open) return <p className={`Snackbar Snackbar--${type}`}>{text}</p>;
  return false;
}

export default Snackbar;
