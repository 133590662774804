import { useDebounce } from '@react-hook/debounce';
import axios from 'axios';
import { useEffect, useState } from 'react';
import SearchInput from './SearchInput';
import './Search.css';

function Search({ nbDisplayedLocations, onSearchResultsChange, onOpenOwner }) {
  const [searchTerm, setSearchTerm] = useDebounce('', 1000);
  const [propertiesSearchResults, setPropertiesSearchResults] = useState([]);
  const [users, setUsers] = useState([]);

  // when term changes
  useEffect(() => {
    axios
      .get(
        `https://nominatim.openstreetmap.org/search?q=${searchTerm}&format=json`
      )
      .then(({ data }) => {
        setPropertiesSearchResults(data);
        onSearchResultsChange(data);
      });

    axios
      .get(`crypto_auth/search_pseudo.php?username=${searchTerm}`)
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [searchTerm]);

  const handleSearchPropertiesAndUsernames = (event) =>
    setSearchTerm(event.target.value.trim());

  return (
    <div className="Search">
      <SearchInput
        placeholder="Search in Tilia.Search..."
        className="App__nav-search"
        onChange={handleSearchPropertiesAndUsernames}
      />
      {(nbDisplayedLocations > 0 || searchTerm) && (
        <ul className="Search__result-text">
          <li className="Search__result-text-search">
            <p>
              <span className="Search__result-text-number">
                {nbDisplayedLocations}
              </span>
              &nbsp;
              {nbDisplayedLocations > 1 ? 'properties' : 'property'} displayed
            </p>
            <button className="Search__result-text-search-contract">
              <img src="location-mini.svg" alt="All locations" />
            </button>
          </li>
          {searchTerm && (
            <li className="Search__result-text-search">
              <p>
                <span className="Search__result-text-number">
                  {propertiesSearchResults.length}
                </span>
                &nbsp;
                {propertiesSearchResults.length > 1 ? 'results' : 'result'}{' '}
                found <label className="Search__result-text-sign"></label>
              </p>
              <button
                className="Search__result-text-search-contract"
                onClick={() => {
                  const results = [...propertiesSearchResults];
                  setPropertiesSearchResults(results);
                  onSearchResultsChange(results);
                }}
              >
                <img src="contract-mini.svg" alt="Zoom out" />
              </button>
            </li>
          )}
          {users.length > 0 && (
            <li className="Search__result-text-search">
              <ul className="Search__result-users">
                {users.map((u) => (
                  <li key={u.username}>
                    <button
                      className="Search__result-users-item-button"
                      onClick={() => onOpenOwner(u.wallet_address)}
                    >
                      {u.username}
                    </button>
                  </li>
                ))}
              </ul>
              <button className="Search__result-text-search-contract">
                <img src="user-profile.svg" alt="Users" />
              </button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

export default Search;
